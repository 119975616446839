import { AnyObject } from 'final-form';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PatientProfileDetails } from '../components';
import { CaseRecordTransaction, PatientFragment, useServiceRequestWithDocumentsInfoLazyQuery } from '../graphql';
import { AppRootState } from '../redux';
import { casedateFormatter, localDateFormat, localDateTableFormatAnother } from '../utils';
import { AddRxAdmin } from './AddRxAdmin';
import { CaseDetailMainSection } from './CaseDetailMainSection';
import { DispensesTable } from './DispensesTable';
import { PatientCaseDocumentsTable } from './DocumentsTable';
import { PatientCasesTable } from './PatientCasesTable';
import { RequestHistoryTable } from './RequestHistoryTable';
import { RxAdminTable } from './RxAdminTable';
import flagsmith from 'flagsmith';

interface PatientDetailProps {
  patient?: PatientFragment | null;
  caseId?: string;
}

interface PatientDocumentProps {
  patientId?: string | null;
  fileName?: string | null;
  documentType?: string | null;
  documentId?: string | null;
  caseDrug?: string | null;
  authorizationReceived?: boolean | null;
  created: string;
  caseRecordId?: number | null;
  caseMileStone?: string | null;
}

export enum TabTitle {
  summary = '#summary',
  cases = '#cases',
  request = '#request',
  documents = '#documents',
  dispenses = '#dispenses',
  rxAdmin = '#rxadmin'
}

export const PatientDetailMainSection = ({ patient }: PatientDetailProps) => {
  const url = useLocation();
  const currentUser = useSelector((state: AppRootState) => state.auth?.session?.user);
  const [execQuery, result] = useServiceRequestWithDocumentsInfoLazyQuery();

  const rxAdminTableRef = useRef<{ invalidateTable: () => void }>();
  const addRxAdminRef = useRef<{ invalidateAddRxAdmin: () => void }>();

  useEffect(() => {
    if (!patient || !currentUser) return;

    execQuery({
      variables: {
        patientId: patient.id,
        organizationId: String(currentUser.OrganizationId),
        programId: String(currentUser.ProgramId)
      }
    });
  }, [execQuery, currentUser, patient]);

  const serviceRequest = result?.data?.result;
  const generateDocumentType = () => {
    const documentArray: PatientDocumentProps[] = [];
    serviceRequest?.map((sr) => {
      sr?.documents?.map((d) => {
        documentArray.push({
          patientId: sr?.patientId ?? null,
          fileName: d?.fileName ?? null,
          documentType: d?.documentType ?? null,
          documentId: d?.id ?? null,
          caseDrug: sr?.sRDrugName ?? null,
          authorizationReceived: d?.signatureRequired ?? null,
          created: d?.created ?? new Date().toLocaleString() + '',
          caseRecordId: sr?.caseRecordTransaction?.[0]?.caseRecordId ?? null,
          caseMileStone: sr?.caseRecordTransaction?.[0]?.caseMileStone ?? null
        });
      });
    });
    return documentArray;
  };

  const documents = generateDocumentType()
    .sort((a, b) => a.created.localeCompare(b.created))
    .reverse();
  return (
    <div className='flex-grow-1 m-4 flex-column overflow-auto'>
      {/* <div className='h5'>Case #:</div> */}
      {url?.hash.includes(TabTitle.summary) && (
        <>
          <div className='h5 cmx__profile-tab-title'>OVERVIEW</div>
          <Card>
            <Card.Body>
              <PatientProfileDetails patient={patient} />
            </Card.Body>
          </Card>
        </>
      )}
      {url?.hash.includes(TabTitle.cases) && (
        <>
          <div className='h5 cmx__profile-tab-title'>CASES</div>{' '}
          <Card>
            <Card.Body>
              <PatientCasesTable enableExternalFilters />
            </Card.Body>
          </Card>
        </>
      )}
      {url?.hash.includes(TabTitle.request) && (
        <>
          <div className='h5 cmx__profile-tab-title'>REQUESTS</div>
          <Card>
            <Card.Body>
              <RequestHistoryTable serviceRequests={serviceRequest} />
            </Card.Body>
          </Card>
        </>
      )}
      {url?.hash.includes(TabTitle.documents) && (
        <>
          <div className='h5 cmx__profile-tab-title'>DOCUMENTS</div>
          <Card>
            <Card.Body>
              <PatientCaseDocumentsTable caseDocuments={documents} patientId={patient?.id} />
            </Card.Body>
          </Card>
        </>
      )}
      {url.hash.includes('#caseDetail') && (
        <>
          <CaseDetailMainSection />
        </>
      )}
      {url.hash.includes('#bi') && (
        <>
          <CaseDetailMainSection />
        </>
      )}
      {url.hash.includes('#pa') && (
        <>
          <CaseDetailMainSection />
        </>
      )}
      {url.hash.includes('#enrollment') && (
        <>
          <CaseDetailMainSection />
        </>
      )}
      {url.hash.includes('#appeal') && (
        <>
          <CaseDetailMainSection />
        </>
      )}
      {!url.hash && (
        <>
          <CaseDetailMainSection />
        </>
      )}
      {/* <DispenesTable  /> */}
      {url?.hash.includes(TabTitle.dispenses) && (
        <>
          <div className='h5 cmx__profile-tab-title'>DISPENSES</div>
          <Card>
            <Card.Body>
              <DispensesTable />
            </Card.Body>
          </Card>
        </>
      )}
      {url?.hash.includes(TabTitle.rxAdmin) && (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='h5 cmx__profile-tab-title'>
              RX ADMINISTRATION
              {flagsmith.hasFeature('impl_23841') && (
                <span className='ml-4'>Injection date captured from interactions and may have changed</span>
              )}
            </div>
            <AddRxAdmin
              ref={addRxAdminRef}
              patientId={patient?.id}
              onUpdated={() => rxAdminTableRef?.current?.invalidateTable()}
            />
          </div>
          <Card>
            <Card.Body>
              <RxAdminTable
                ref={rxAdminTableRef}
                onUpdated={() => addRxAdminRef?.current?.invalidateAddRxAdmin()}
                patientId={patient?.id}
              />
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
};
