import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ResourceContent } from './multi-tenant';
import { useSelector } from 'react-redux';
import { AppRootState } from '../redux';
import { AnyType } from '../interfaces';
import flagsmith from 'flagsmith';
import { ROUTE_PATHS } from '../utils/constants';
import { matchPath } from 'react-router-dom';
interface SubToolbarProps extends PropsWithChildren<unknown> {
  pageTitle?: string;
  contentKey?: string;
}
export const SubToolbar = ({ children, pageTitle, contentKey }: SubToolbarProps) => {
  const isHeaderText = useSelector(
    (state: AppRootState) =>
      ((state.app?.entities?.resources as AnyType) ?? {})['banner-data-sample-test-info'] as AnyType
  );
  const bannerTextEnabled = useSelector(
    (state: AppRootState) =>
      (((state.app?.entities?.features as AnyType) ?? {})['banner-sample-text' ?? ''] as AnyType)?.data?.enabled
  );
  const [matches, setmatches] = useState(false);
  const [urlMatch, seturlmatch] = useState(false);
  useEffect(() => {
    // Get the current URL path
    const pathname = decodeURIComponent(window.location.pathname);
    const reqPageUrl = ROUTE_PATHS.viewRequestEnrollment;
    const ismatch = matchPath(pathname, { path: reqPageUrl });
    const startUrl = ROUTE_PATHS.serviceRequestForPatient;
    const startUrlMatch = matchPath(pathname, { path: startUrl });
    if (ismatch) {
      setmatches(true);
    } else {
      setmatches(false);
    }
    if (startUrlMatch) {
      seturlmatch(true);
    } else {
      seturlmatch(false);
    }
  }, [window.location.pathname]);
  return flagsmith.hasFeature('impl_24175') ? (
    <div className='container-fluid bg-primary py-2'>
      <div className='row align-items-center'>
        <div className='col'>
          {' '}
          {/* Heading */}{' '}
          {pageTitle?.length && (
            <>
              <h4 className='text-uppercase text-white font-weight-bold text-truncate mt-0 mb-0'>
                {pageTitle?.length ? pageTitle : <ResourceContent contentKey={contentKey || ''} />}
              </h4>
              {matches && children && <div style={{ minWidth: '260px' }}>{children}</div>}
              {urlMatch && <span className='text-white p max-w-50'>{isHeaderText?.data?.content}</span>}
            </>
          )}{' '}
          {/* Text and button side by side */}
          {!matches && (
            <div className='d-flex justify-content-between align-items-center'>
              {!matches && <span className='text-white p max-w-50'>{isHeaderText?.data?.content}</span>}
              {/* Render the button only for the view request page */}
              {children && <div style={{ minWidth: '260px' }}>{children}</div>}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Container fluid className='d-flex align-items-center bg-primary flex-wrap h-auto py-3'>
      <Container>
        <Row lg={2} xs={1} className='justify-content-between align-items-center'>
          <Col>
            {bannerTextEnabled ? (
              <h6 className='text-uppercase text-white font-weight-bold text-truncate'>
                {isHeaderText?.data?.content}
              </h6>
            ) : null}
            <h4
              className='text-uppercase text-white font-weight-bold text-truncate'
              data-testid='cmx__subtoolbar-title'>
              {pageTitle?.length ? pageTitle : <ResourceContent contentKey={contentKey || ''} />}
            </h4>
          </Col>
          <Col className='justify-content-end d-flex'>{children}</Col>
        </Row>
      </Container>
    </Container>
  );
};
